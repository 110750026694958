<template>
  <div v-if="invoice">
    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a @click="sendMailToLocation()" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'paper-plane']" />
            </span>
            <span>Send mail to location</span>
          </a>

          <LinkDownload
            type="feeinvoice"
            :id="invoice.Id"
            iconCssClass="is-small"
          />

          <a
            v-if="invoice.StatusId === 1"
            @click="creditInvoice(invoice)"
            class="has-icon"
            :class="{ 'is-loading': isCreditting }"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['far', 'shredder']" />
            </span>
            <span>Credit</span>
          </a>

          <a
            v-if="invoice.PaymentStatusId !== 3"
            @click="changePaymentStatus(invoice)"
            class="has-icon"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'coins']" />
            </span>
            <span>Mark as paid</span>
          </a>

          <a
            v-if="invoice.PaymentStatusId === 3"
            @click="changePaymentStatus(invoice)"
            class="has-icon"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'coins']" />
            </span>
            <span>Undo mark as paid</span>
          </a>

          <a
            v-if="
              invoice.PaymentStatusId !== 3 && invoice.ReminderStatusId === 0
            "
            @click="sendFirstReminder(invoice)"
            class="has-icon"
            :class="{ 'is-loading': isSendingReminder }"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'paper-plane']" />
            </span>
            <span>Send 1st reminder</span>
          </a>
          <a
            v-if="
              invoice.PaymentStatusId !== 3 && invoice.ReminderStatusId === 1
            "
            @click="sendSecondReminder(invoice)"
            class="has-icon"
            :class="{ 'is-loading': isSendingReminder }"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'paper-plane']" />
            </span>
            <span>Send 2nd reminder</span>
          </a>
          <a
            v-if="
              invoice.PaymentStatusId !== 3 &&
                invoice.ReminderStatusId === 2 &&
                !invoice.LocationIsLocked
            "
            @click="lockLocation(invoice)"
            class="has-icon"
            :class="{ 'is-loading': isLocking }"
            ><span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'lock']" />
            </span>
            <span>Lock location</span></a
          >

          <!-- <a @click="setShowModalSendInvoice" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'paper-plane']" />
            </span>
            <span>{{
              $t('Components.Invoices.InvoiceDetail.Button_SendMail')
            }}</span>
          </a> -->
        </div>
      </div>
    </div>

    <section class="is-clearfix section is-small">
      <div class="container">
        <table class="table is-narrow is-pulled-right">
          <tbody>
            <tr>
              <td colspan="2" class="has-text-right">
                <span class="title"
                  >{{ invoice.Year }}-{{ invoice.Number }}</span
                >
              </td>
            </tr>
            <tr>
              <td width="150">Location</td>
              <td width="250" class="has-text-right">
                <span>{{ invoice.LocationName }}</span>
              </td>
            </tr>
            <tr>
              <td width="150">Address</td>
              <td width="250" class="has-text-right">
                <div>{{ invoice.LocationAddress }}</div>
                <div>{{ invoice.LocationZipcode }}</div>
                <div>{{ invoice.LocationCity }}</div>
              </td>
            </tr>
            <tr>
              <td width="150">Issue date</td>
              <td width="250" class="has-text-right">
                <span>{{
                  invoice.ActivationDate | dateObjectIsoDateString
                }}</span>
              </td>
            </tr>
            <tr>
              <td width="150">Expiration date</td>
              <td width="250" class="has-text-right">
                <span>{{
                  invoice.ExpirationDate | dateObjectIsoDateString
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="is-clearfix section">
      <div class="container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Description</th>
              <th class="has-text-right">Tax</th>
              <th class="has-text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="line in invoice.Lines" :key="line.Id">
              <td>{{ line.Quantity }}x</td>
              <td>{{ line.Text }}</td>
              <td class="has-text-right">{{ line.TaxPercentage }}%</td>
              <td width="150" class="has-text-right">
                <span v-html="invoice.CurrencySymbol"></span>
                <span>{{ line.PriceTotal | toCurrency }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="is-clearfix section is-small">
      <div class="container">
        <table class="table is-narrow is-pulled-right">
          <tbody>
            <tr>
              <td width="250">Total excl tax</td>
              <td width="150" class="has-text-right">
                <span>{{
                  invoice.TotalExclTax
                    | toCurrency('en-US', invoice.CurrencyIso)
                }}</span>
              </td>
            </tr>
            <tr>
              <td>Total tax 21%</td>
              <td class="has-text-right">
                <span>{{
                  (invoice.TotalInclTax - invoice.TotalExclTax)
                    | toCurrency('en-US', invoice.CurrencyIso)
                }}</span>
              </td>
            </tr>
            <tr>
              <td>Total incl tax</td>
              <td class="has-text-right">
                <span>{{
                  invoice.TotalInclTax
                    | toCurrency('en-US', invoice.CurrencyIso)
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="section is-size-7">
      <div class="container">
        <div
          v-if="invoice.FooterText"
          v-html="invoice.FooterText"
          class="actionBar has-background-white-ter has-padding has-margin-bottom"
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'

import invoiceProvider from '@/providers/feeInvoice'
import locationProvider from '@/providers/location'

const LinkDownload = () => import('@/components/UI/LinkDownload.vue')

export default {
  components: {
    LinkDownload,
  },

  props: {
    invoiceId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      invoice: null,
      isCreditting: false,
      isLocking: false,
      isSendingReminder: false,
    }
  },

  computed: {},

  created() {
    this.getInvoiceData()
  },

  methods: {
    getInvoiceData() {
      invoiceProvider.methods
        .getInvoiceById(this.invoiceId)
        .then((response) => {
          if (response.status === 200) {
            this.invoice = response.data
          }
        })
    },

    getStatusName(statusId) {
      let name = ''

      switch (statusId) {
        case 1:
          name = 'Final'
          break
        case 2:
          name = 'Frozen'
          break
        case 3:
          name = 'Credit'
          break
      }

      return name
    },

    creditInvoice(value) {
      this.selectedInvoiceId = value.Id
      this.isCreditting = true

      invoiceProvider.methods
        .creditFeeInvoice(value.Id)
        .then((response) => {
          value.StatusId = 2
        })
        .then(() => {
          this.isCreditting = false
          this.selectedInvoiceId = 0
        })
    },

    changePaymentStatus(value) {
      let paymentStatusId = 0

      if (value.PaymentStatusId === 0) {
        paymentStatusId = 3
      }

      invoiceProvider.methods
        .changePaymentStatus(value.Id, paymentStatusId)
        .then((response) => {
          value.PaymentStatusId = response.data.PaymentStatusId
        })
    },

    sendMailToLocation() {
      invoiceProvider.methods
        .sendInvoiceLocationMail(this.invoiceId)
        .then((response) => {
          if (response.status === 204) {
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Mail send!',
            })
          }
        })
    },

    sendFirstReminder(value) {
      this.selectedInvoiceId = value.Id
      this.isSendingReminder = true

      invoiceProvider.methods
        .sendFirstReminder(value.Id)
        .then((response) => {
          value.ReminderStatusId = 1
        })
        .finally(() => {
          this.isSendingReminder = false
          this.selectedInvoiceId = 0
        })
    },

    sendSecondReminder(value) {
      this.selectedInvoiceId = value.Id
      this.isSendingReminder = true

      invoiceProvider.methods
        .sendSecondReminder(value.Id)
        .then((response) => {
          value.ReminderStatusId = 2
        })
        .finally(() => {
          this.isSendingReminder = false
          this.selectedInvoiceId = 0
        })
    },

    lockLocation(value) {
      this.selectedInvoiceId = value.Id
      this.isLocking = true

      locationProvider.methods
        .lockLocation(value.LocationId, 2)
        .then((response) => {
          value.LocationIsLocked = true
        })
        .finally(() => {
          this.isLocking = false
          this.selectedInvoiceId = 0
        })
    },

    unlockLocation(value) {
      this.isLocking = true

      locationProvider.methods
        .unlockLocation(value.LocationId)
        .then((response) => {
          value.LocationIsLocked = false
        })
        .finally(() => {
          this.isLocking = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
